<template>
    <layout>
        <template v-slot:header>
            <div class="pb-10 pb-sm-0">
                <div class="agentpage-mainblock w-100 index2 p-relative">
                    <v-container >
                        <breadcrumbs :items="breadcrumbs" :large="true" class="py-7 mb-12 d-none d-md-block" :textcolor="`textwhite`"></breadcrumbs>
                        <h1 class="heading-1 white--text">Join Our Team <br /> Become an Agent</h1>
                        <div class="text-16 white--text mt-6 mb-12">Boost your income and get the freedom <br /> for creativity by joining Freight Crew team</div>
                        <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" @click.stop="$refs.agentform.changeDialog()" depressed>Become an agent <v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-container>
                </div>
                <div class="agentpage-footerblock w-100 pt-16 pb-8 d-none d-sm-block index2 p-relative">
                    <v-container>
                        <v-list color="transparent" class="mx-auto" flat>
                            <v-list-item-group class="d-flex flex-row align-self-center justify-space-between h-100">
                                <v-list-item class="pl-0" :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-md-center align-md-center text-20-60 d-block d-md-flex ">
                                            <img src="@/assets/icons/svg/star-white-rounded.svg" alt="Expedited shipping Icon" class="mr-5">
                                            <span class="border white--text d-block">Unlimited income</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-md-center align-md-center  text-20-60 d-block d-md-flex">
                                            <img src="@/assets/icons/svg/star-white-rounded.svg" alt="LTL shipping Icon" class="mr-5">
                                            <span class="border white--text d-block">Freedom and creativity</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item :ripple="false">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-md-center align-md-center  text-20-60 d-block d-md-flex">
                                            <img src="@/assets/icons/svg/star-white-rounded.svg" alt="International shipping Icon" class="mr-5">
                                            <span class="border white--text d-block">Development assistance</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-container>
                </div>
                <v-img class="agentpage-mainimage d-none d-sm-block" src="@/assets/icons/agents/agents.svg" alt=""></v-img>
                <img class="agentpage-mainimage d-block d-sm-none" src="@/assets/icons/agents/agents-xs.svg" alt=""></img>
            </div>
        </template>
        <template v-slot:main>
            <div class="py-4 py-sm-16 mt-16 mt-sm-0">
                <v-container class="mt-4">
                    <h1 class="heading-2 mb-10">How to Become an Agent <br /> for Freight Crew?</h1>
                    <horizontal-list :icon="becomeagenticon" :items="becomeagent"/>
                </v-container>
            </div>
            <div class="second-silver py-4 py-sm-16">
                <v-container>
                    <join-our-team />
                </v-container>
            </div>
            <counter :bgimg="require('@/assets/logo/bg-logo-blue-full.svg')">
                <h1 class="heading-2 white--text mb-10">Why Work With Us?</h1>
            </counter>
            <div class="">
                <v-container class="py-0">
                    <v-row>
                        <v-col cols="12" md="8" lg="6" class="py-16">
                            <h1 class="heading-2 my-8">Increase your income joining Freight Crew team</h1>
                            <p class="text-16 my-12">Joining our team you get the opportunity to boost your income and make extra money. Our wide range of transportation and logistics services will assure you’ll be able to offer your customers anything they need, putting you ahead of the rest and establishing yourself as a trustful logistics services provider</p>
                            <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" @click.stop="$refs.agentform.changeDialog()" depressed>Become an agent <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" md="4" lg="6" class="py-0">
                            <v-img src="@/assets/icons/agents/money.svg" class="d-none d-lg-block mx-8"/>
                            <v-img src="@/assets/icons/svg/money-md.svg" class="d-none d-md-block d-lg-none ml-8" style="position: absolute;right: 0;"/>
                            <v-img src="@/assets/icons/svg/money-xs.svg" class="d-block d-sm-none ml-8" style="width:153px;position: absolute;right: 0;margin-top:-80px;"/>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <cooperation/>
            <div class="contact primary py-md-16">
                <v-container class="my-6">
                    <contact-us />
                </v-container>
            </div>
            <agent-form ref="agentform"/>
        </template>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import HorizontalList from '@/components/base/HorizontalList.vue'
import JoinOurTeam from '@/components/pages/JoinOurTeam.vue'
import Counter from '@/components/pages/Counter.vue'
import Cooperation from '@/components/pages/Cooperation.vue'
import ContactUs from '@/components/pages/ContactUs.vue'
import AgentForm from '@/components/pages/AgentForm.vue'

export default {
    name: 'Agents',
    components: {
        Layout,
        JoinOurTeam,
        Breadcrumbs,
        HorizontalList,
        Counter,
        Cooperation,
        ContactUs,
        AgentForm
    },
    data: () => ({
        agent_dialog: false,
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Agents',
                disabled: true,
            }
        ],
        becomeagenticon: {
            class: 'mb-4',
            icon: require('@/assets/icons/svg/blue-point.svg')
        },
        becomeagent: [
            {
                icontitle: require('@/assets/icons/agents/become/mail.svg'),
                titleclass: 'mb-8 h-48px d-flex',
                text: 'Fill in the forms and <br/> submit your application',
            },
            {
                icontitle: require('@/assets/icons/agents/become/list.svg'),
                titleclass: 'mb-8 h-48px d-flex',
                text: 'We review <br/> your application',
            },
            {
                icontitle: require('@/assets/icons/agents/become/phone.svg'),
                titleclass: 'mb-8 h-48px d-flex',
                text: 'We invite you <br/> for a job interview',
            },
            {
                icontitle: require('@/assets/icons/agents/become/head.svg'),
                titleclass: 'mb-8 h-48px d-flex',
                text: 'Congratulations, <br/> you’re a part of our team!',
            }
        ]
    })
}
</script>

<style lang="scss">
.primary.header{
    position: relative;
    overflow: hidden;
    header{
        z-index: 1;
    }
    .v-btn.v-size--x-large{
        font-weight: 450;
        font-size: 14px;
        line-height: 22px;
        text-transform: none;
    }
    .agentpage-mainimage{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
    }
}
.h-48px{
    height: 48px;
}
@media all and (min-width:600px) and (max-width: 1273px){
    .contact.primary{
        height: 610px;
        margin-bottom: 140px;
    }
}
@media all and (max-width:600px){
    .primary.header{
        overflow: initial;
        .agentpage-mainimage{
            position: absolute;
            bottom: -130px;
            right: 0;
            width: 150px;
            height: auto;
            top: auto;
            z-index: 20;
        }
    }
}
</style>
