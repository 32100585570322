<template>
    <div class="hlc">
        <v-list>
            <v-list-item-group>
                <v-list-item v-for="element in items" class="d-flex d-lg-none align-center iconafterdash py-2 pl-0 pl-sm-auto px-0 pr-sm-auto"  :ripple="false">
                    <v-list-item-icon class="align-self-center my-0 mr-0 order-1 order-sm-0">
                        <img src="@/assets/icons/svg/blue-point.svg" class="" alt="">
                    </v-list-item-icon>
                    <v-list-item-content class="order-0 order-sm-1">
                        <v-list-item-title class="white-space-initial">
                            <div class="d-flex align-center">
                                <div v-if="element.icontitle" class="listicon">
                                    <img :src="element.icontitle" alt="" class="ml-0 mr-5 mx-sm-10 listiconimg">
                                </div>
                                <div class="black--text text-20-45 white-space-initial titleiconleft" v-html="element.text"></div>
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <v-list color="transparent" class="horizontal-list-component d-none d-lg-block">
            <v-list-item-group class="d-flex justify-space-between">
                <v-list-item v-for="item in items" class="d-block" :ripple="false">
                    <v-list-item-icon :class="icon.class">
                        <img :src="icon.icon" alt="">
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-if="item.title" v-text="item.title" :class="item.titleclass"></v-list-item-title>
                        <v-list-item-title v-if="item.icontitle" :class="item.titleclass">
                            <img :src="item.icontitle" alt="">
                        </v-list-item-title>
                        <div v-if="item.text" class="desctext" v-html="item.text"></div>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
export default {
    name: 'HorizontalList',
    props: ['icon', 'items']
}
</script>

<style lang="scss">
.desctext{
    color: #1F1F1F;
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 28px;
}
.horizontal-list-component::before{
    content: " ";
    position: absolute;
    border-top: 1px dashed #91B9FB;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 28px;
}
.hlc{
    .iconafterdash:not(:last-child)::before{
        background-color: transparent;
        border: 1px dashed #91B9FB;
        content: " ";
        height: 100%;
        position: absolute;
        opacity: 1;
        right: auto;
        left: 27px;
        top: 60%;
    }
    .iconafterdash:hover::before{
        opacity: 1 !important;
    }
    .v-list-item--link::before{
        background-color: transparent;
    }
    @media all and (max-width: 1264px){
        .listicon{
            width: 120px;
        }
        .iconafterdash:not(:last-child)::before{
            left: 11px;
        }
    }
    @media all and (max-width: 600px){
        .listicon{
            width: 60px;
        }
        .listiconimg{
            max-height: 45px;
            max-width: 45px;
        }
        .iconafterdash:not(:last-child)::before{
            right: 11px;
            left: auto;
            top: 60%;
        }
    }
}
</style>
